@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/*!* Firefox < 16 *!*/
/*@-moz-keyframes fadein {*/
/*    from { opacity: 0; }*/
/*    to   { opacity: 1; }*/
/*}*/

/*!* Safari, Chrome and Opera > 12.1 *!*/
/*@-webkit-keyframes fadein {*/
/*    from { opacity: 0; }*/
/*    to   { opacity: 1; }*/
/*}*/

/*!* Internet Explorer *!*/
/*@-ms-keyframes fadein {*/
/*    from { opacity: 0; }*/
/*    to   { opacity: 1; }*/
/*}*/

/*!* Opera < 12.1 *!*/
/*@-o-keyframes fadein {*/
/*    from { opacity: 0; }*/
/*    to   { opacity: 1; }*/
/*}*/


@keyframes fadeout {
    to   { opacity: 0; }
    from { opacity: 0.5; }
}


@keyframes goUpAnimation {
    0%   {top: calc(100% - 42px) }
    100%   {top: 0 }
}

@keyframes goDownAnimation {
    0%   {top: 0 }
    100%   {top: calc(100% - 42px) }
}